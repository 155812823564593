#topbar {
  height: 62px;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #111118;
  user-select: none;
  font-family: Comfortaa;
  margin: 0;
  z-index: 999;
  overflow: hidden;
}

.github-logo {
  transition: all .25s;
  filter: brightness(.4);
  padding: 10px;
  height: 100%;
  box-sizing: border-box;
  width: auto;
  float: right;
}

.topbar:hover .github-logo {
  filter: brightness(.7);
}

.tb-title {
  font-weight: bold;
}

.tb-item {
  transition: all .25s;
  width: max-content;
  padding: 22px;
  margin: 0;
  white-space: nowrap;
  cursor: pointer;
  float: left;
}

.tb-item:hover {
  background-color: #222;
  color: #a8c9ff;
}

@media (max-width: 500px) {
  .github-logo {
    display: none;
  }
}

@media (max-width: 340px) {
  .tb-item {
    padding: 10px;
  }
}