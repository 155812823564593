.main-img {
  margin-top: 100px;
  height: auto;
}

.canvas-container {
  position: absolute;
  z-index: -9999;
  top: 0;
  left: 0;
}