#main-title {
  position: absolute;
  font-family: Roboto Slab;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;

  .first {
    font-size: 100px;
    font-weight: bold;
    margin: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(110deg, rgb(50, 180, 232), rgb(48, 225, 194));
  }

  .second {
    font-size: 59px;
    font-weight: bold;
    margin: 0;
    margin-top: -25px;
  }

  .subtitle {
    font-family: Caveat;
    font-size: 36px;
    transform: translateX(5%) rotate(-6deg);
  }

  @media (max-width: 390px) {
    .first {
      font-size: 25vw;
    }

    .second {
      font-size: 31vw;
      margin-top: calc(-25px + 2vw * 2.5);
    }
  }
}
