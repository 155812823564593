#footer {
  margin-top: 200px;
  background-color: #202330;
  padding: 30px 0;
  overflow: hidden;

  table {
    margin: 5px 0 25px 0;
  }

  h1, p {
    margin: 0;
  }

  p {
    padding-top: 20px;
  }

  .name {
    padding-right: 20px;
  }

  @media (max-width: 430px) {
    .name {
      display: none;
    }
  }
}