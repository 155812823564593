#notfound {
  width: max-content;
  height: max-content;
  transform: translate(-50%, -50%);
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;

  .title {
    font-size: 100px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(110deg, rgb(50, 180, 232), rgb(48, 225, 194));
    margin: 0;
  }

  .subtitle {
    margin: 0;
  }
}
