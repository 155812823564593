* {
  outline: none;
}

body {
  font-family: Roboto !important;
  background-color: #111118 !important;
  color: #eee !important;
  overflow-x: hidden;
  margin: 100vh 0 0 0 !important;
  scroll-behavior: smooth;
}

img {
  user-select: none;
}

h1, h2 {
  cursor: default;
}

b {
  font-weight: 900;
}

a {
  color: #acf;
  text-decoration: none;
  transition: all .1s;
}

a:hover {
  color: #ddd;
}

.top {
  height: 100%;
}

.center-block {
  max-width: 550px;
  margin: 0 auto;
  padding: 0 20px;
}

.center {
  margin: 0 auto;
  width: max-content;
}

.ti-sub {
  font-size: 50px;
  font-weight: bolder;
  margin: 0;
}

.text {
  color: #aaa;
  font-size: 20px;
}

.selected, ::selection, ::-moz-selection {
  background: #adf;
  color: #eee;
}