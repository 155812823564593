// linear-gradient(to right, #FF6EAB, #FFBF85);

.container {
  z-index: -999;
  transform: translate(0, -50%);
  position: fixed;
  top: 0;
  left: 0;
  opacity: .3;

  & > div {
    display: flex;
    grid-template-columns: 220px 220px;
    transform: rotateX(-63.5deg) rotateZ(-50deg) translateY(37%);

    .tile {
      border: 3px solid;
      border-radius: 7px;
      width: 200px;
      padding: 20px;
      margin-right: 20px;
      margin-bottom: 20px;
      word-break: break-all;

      .secondary {
        border: 3px solid;
        border-radius: 7px;
        width: 100%;
        height: 100%;
        animation: float 5s infinite ease-in-out;
      }
    }
  }
}

@keyframes float {
  0% {
    transform: translate(10px, -10px);
  }

  50% {
    transform: translate(35px, -35px);
  }

  100% {
    transform: translate(10px, -10px);
  }
}

// .container {
//   z-index: -999;
//   transform: translate(0, -50%);
//   position: fixed;
//   top: 0;
//   left: 0;
//   opacity: .3;

//   & > div {
//     display: flex;
//     // grid-template-columns: 220px 220px;
//     // transform: rotateX(-63.5deg) rotateZ(-50deg) translateY(37%);
//     transform: rotate(-30deg) skew(40deg);
//     // grid-gap: -10px;

//     .tile {
//       border: 3px solid;
//       border-radius: 7px;
//       width: 200px;
//       padding: 20px;
//       // margin-right: 20px;
//       // margin-bottom: 20px;
//       margin: -50px -20px;
//       word-break: break-all;

//       .secondary {
//         border: 3px solid;
//         border-radius: 7px;
//         width: 100%;
//         height: 100%;
//         animation: float 5s infinite ease-in-out;
//       }
//     }
//   }
// }

// // @keyframes float {
// //   0% {
// //     transform: translate(10px, -10px);
// //   }

// //   50% {
// //     transform: translate(35px, -35px);
// //   }

// //   100% {
// //     transform: translate(10px, -10px);
// //   }
// // }

// @keyframes float {
//   0% {
//     transform: translateY(-15px);
//   }

//   50% {
//     transform: translateY(-40px);
//   }

//   100% {
//     transform: translateY(-15px);
//   }
// }