.grid {
  overflow: hidden;
  width: max-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  .item {
    position: relative;
    width: 100%;
    height: 180px;
    background-size: cover;
    overflow: hidden;
    transition: all .25s;
    border-radius: 12px;
  }

  .item:hover>.item-img {
    filter: brightness(.7) saturate(.8);
  }

  .item-img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    filter: brightness(.4) saturate(.3);
    transition: all .25s;
  }

  .item-title {
    white-space: nowrap;
    font-size: 40px;
    position: absolute;
    margin: 0;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    bottom: 10%;
    left: 8%;
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: 610px) {
    grid-template-columns: 1fr !important;
    width: calc(100vw - 60px) !important;
  }

  @media (max-width: 1299px) {
    grid-template-columns: 1fr 1fr;
    max-width: 550px;
  }

  @media (min-width: 1300px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 90vw;
    max-width: 1200px;
  }
}