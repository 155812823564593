@font-face {
  font-family: "Caveat";
  src: url("../assets/fonts/Caveat.ttf");
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto.ttf");
}

@font-face {
  font-family: "Roboto Slab";
  src: url("../assets/fonts/RobotoSlab.ttf");
}

@font-face {
  font-family: "Comfortaa";
  src: url("../assets/fonts/Comfortaa.ttf");
}